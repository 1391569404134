import * as React from "react";
import { UitkHeading } from "@egds/react-core/text";
import { ThumbnailImageLeftCardModuleTitleProps } from "../../typings";

const ThumbnailImageLeftCardModuleTitle = (props: ThumbnailImageLeftCardModuleTitleProps) => {
  return (
    <UitkHeading tag="h2" size={4}>
      {props.model.title}
    </UitkHeading>
  );
};

export default ThumbnailImageLeftCardModuleTitle;
