import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkFigure, UitkImage } from "@egds/react-core/images";
import { UitkText, UitkHeading } from "@egds/react-core/text";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkCardContentSection, UitkCardLink, UitkCard } from "@egds/react-core/cards";
import { getAuthorFromCityStayedAtHotelText, getPostedAgoText } from "../../hotelRecentReviewsUtils";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { UitkSpacing } from "@egds/react-core/spacing";
import { ThumbnailImageLeftCardProps } from "../../typings";

const ThumbnailImageLeftCard = (props: ThumbnailImageLeftCardProps) => {
  const { model, hotel } = props;
  const { subheadingForThumbnailImageLeftCardView, brandName } = model;
  const {
    hotelName,
    hotelImageUrl,
    hotelInfositeUrl,
    date: reviewPostedDate,
    rating,
    reviewTitle,
    reviewText,
    authorName,
    authorLocation,
  } = hotel;

  const { formatText } = useLocalization();

  const reviewTitleToDisplay = () => {
    const title =
      subheadingForThumbnailImageLeftCardView === "brandVerifiedReview"
        ? formatText("hotelRecentReviews.verifiedBrandReview", brandName)
        : reviewTitle;

    return (
      <UitkSpacing margin={{ blockend: "two" }}>
        <UitkText size={400}>{title}</UitkText>
      </UitkSpacing>
    );
  };

  return (
    <UitkCard>
      <UitkCardContentSection>
        <UitkLayoutFlex>
          <UitkLayoutFlexItem minWidth="third_width" shrink={0}>
            <div>
              <UitkFigure className="uitk-card-roundcorner-all" imageFit="cover" ratio={UitkFigure.AspectRatio.R4_3}>
                <UitkImage src={hotelImageUrl} alt={hotelName} placeholderImage />
              </UitkFigure>
            </div>
          </UitkLayoutFlexItem>
          <UitkLayoutFlexItem grow={1}>
            <UitkSpacing margin={{ inlinestart: "three" }}>
              <UitkLayoutFlex direction="column" justifyContent="space-between">
                <UitkHeading tag="h3" size={5} overflow="truncate-2-lines">
                  {hotelName}
                </UitkHeading>
                <UitkLayoutFlex direction="column">
                  <UitkText size={300}>{formatText("hotelRecentReviews.ratingOutOf5", rating)}</UitkText>
                  <UitkText size={200}>{getPostedAgoText(reviewPostedDate)}</UitkText>
                </UitkLayoutFlex>
              </UitkLayoutFlex>
            </UitkSpacing>
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
      </UitkCardContentSection>
      <UitkCardContentSection border="top">
        {reviewTitleToDisplay()}
        <UitkSpacing margin={{ blockend: "two" }}>
          <UitkText size={200} overflow="truncate-2-lines">
            {`\u0022${reviewText}\u0022`}
          </UitkText>
        </UitkSpacing>
        <UitkText size={200} overflow="truncate-2-lines">
          {getAuthorFromCityStayedAtHotelText(authorName, authorLocation, hotelName)}
        </UitkText>
      </UitkCardContentSection>
      <UitkCardLink>
        <TrackedLink
          moduleName="hotelRecentReviews"
          rfrr="hotelInfositeLink"
          href={hotelInfositeUrl}
          data-testid={`hotel-recent-reviews-thumbnail-image-left-card-${hotelName}`}
        >
          {hotelName}
        </TrackedLink>
      </UitkCardLink>
    </UitkCard>
  );
};

export default ThumbnailImageLeftCard;
