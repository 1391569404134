import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkFigure, UitkImage } from "@egds/react-core/images";
import { UitkScrim } from "@egds/react-core/scrim";
import { UitkSubheading, UitkText } from "@egds/react-core/text";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkCard, UitkCardContentSection, UitkCardLink } from "@egds/react-core/cards";

import { getAuthorFromCityStayedAtHotelText, getPostedAgoText, ratingDescription } from "../../hotelRecentReviewsUtils";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { UitkSpacing } from "@egds/react-core/spacing";
import { HeaderImageCardProps } from "../../typings";

const HeaderImageCard = (props: HeaderImageCardProps) => {
  const { model, hotel } = props;
  const { showTitleOfReviewForHeaderImageView } = model;
  const {
    hotelName,
    hotelImageUrl,
    hotelInfositeUrl,
    date: reviewPostedDate,
    rating,
    reviewTitle,
    reviewText,
    authorName,
    authorLocation,
  } = hotel;

  const { formatText } = useLocalization();

  return (
    <UitkCard border>
      <UitkFigure ratio={UitkFigure.AspectRatio.R21_9}>
        <UitkImage src={hotelImageUrl} alt={hotelName} placeholderImage lazyLoading="lazy" />
        <UitkScrim>
          <UitkSubheading tag="h3">
            <UitkText inline size={600} overflow="truncate-2-lines">
              {hotelName}
            </UitkText>
          </UitkSubheading>
        </UitkScrim>
      </UitkFigure>
      <UitkCardContentSection>
        <UitkLayoutFlex direction="column">
          <UitkSpacing margin={{ blockend: "two" }}>
            <UitkText size={400} weight="medium" theme="emphasis">
              {formatText("hotelRecentReviews.ratingSlashNoSpace", rating)} {ratingDescription(rating)}
            </UitkText>
          </UitkSpacing>
          {showTitleOfReviewForHeaderImageView && (
            <UitkSpacing margin={{ blockend: "two" }}>
              <UitkText size={300} weight="medium" theme="emphasis">
                {reviewTitle}
              </UitkText>
            </UitkSpacing>
          )}
          <UitkSpacing margin={{ blockend: "two" }}>
            <UitkText size={300} weight="medium" theme="emphasis" overflow="truncate-2-lines">
              {`\u0022${reviewText}\u0022`}
            </UitkText>
          </UitkSpacing>
          <UitkSpacing margin={{ blockend: "two" }}>
            <UitkText size={200} overflow="truncate-2-lines">
              {getAuthorFromCityStayedAtHotelText(authorName, authorLocation, hotelName)}
            </UitkText>
          </UitkSpacing>
          <UitkText size={200}>{getPostedAgoText(reviewPostedDate)}</UitkText>
        </UitkLayoutFlex>
      </UitkCardContentSection>
      <UitkCardLink>
        <TrackedLink
          moduleName="hotelRecentReviews"
          rfrr="hotelInfositeLink"
          href={hotelInfositeUrl}
          data-testid={`hotel-recent-reviews-header-image-card-${hotelName}`}
        >
          {hotelName}
        </TrackedLink>
      </UitkCardLink>
    </UitkCard>
  );
};

export default HeaderImageCard;
