import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { HeaderImageCardModuleTitleProps } from "../../typings";

const HeaderImageCardModuleTitle = (props: HeaderImageCardModuleTitleProps) => {
  const { title, showSubtitleForHeaderImageView, brandName } = props.model;
  const { formatText } = useLocalization();

  return (
    <>
      <UitkHeading tag="h2" size={4}>
        {title}
        {showSubtitleForHeaderImageView && (
          <UitkText size={300} weight="regular">
            {formatText("hotelRecentReviews.brandVerifiedReviews", brandName)}
          </UitkText>
        )}
      </UitkHeading>
    </>
  );
};

export default HeaderImageCardModuleTitle;
